import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageAttHeader from "../../Modules/TextBlocks/PresAttHeader";

const fanCard = ({src, alt, title, title2, title3, text}) => (
    <Container fluid>
        <Row className="pagecard-wrapper">
            <Col xl={4}>
                <img src={src} alt={alt} className="pagecard-img"/>
            </Col>
            <Col className="pagecard-body">
                <h5 className="pagecardheader">Центробежный вентилятор в гигиеническом исполнении</h5>
                <br/>
                <PageAttHeader 
                text={title}
                />
                <br/>
                <p  style={{whiteSpace: "pre-wrap"}}>{text}</p>
                <br/>
            </Col>
        </Row>
    </Container>
)

export default fanCard;