import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageAttHeader from "../../Modules/TextBlocks/PresAttHeader";

const serviceCard = ({src, alt, title, title2, title3, title4, title5, text}) => (
    <Container fluid>
        <Row className="pagecard-wrapper">
            <Col xl={4}>
                <img src={src} alt={alt} className="pagecard-img"/>
            </Col>
            <Col className="pagecard-body">
                <h5 className="pagecardheader">Удобство обслуживания, применяемые материалы и гигиена</h5>
                <br/>
                <PageAttHeader 
                text={title}
                />
                <br/>
                <p>{text}</p>
                <br/>
                <PageAttHeader 
                text={title2}
                />
                <br/>
                <PageAttHeader 
                text={title3}
                />
                <br/>
                <PageAttHeader 
                text={title4}
                />
                <br/>
                <PageAttHeader 
                text={title5}
                />
            </Col>
        </Row>
    </Container>
)

export default serviceCard;