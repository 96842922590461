import React from 'react';
import PageAttIcon from "../../../../images/icons/page-att-icon.svg"


const PageAttIconImg = () => {
    return (
        <>
            <img src={PageAttIcon} alt="" className='pageattimg'/>
        </>
    )
}

export default PageAttIconImg;