import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCardTemplate from '../ProjectsCards/projectCard';

import EcomilkCardImg from '../../../images/projectspage/projectscards/ecomilk.jpg';
import MolokoKutuzaCardImg from '../../../images/projectspage/projectscards/moloko_kutuz.jpg';
import MolokoKalugaCardImg from '../../../images/projectspage/projectscards/moloko_kaluga.jpg';
import CheesePleaseCardImg from '../../../images/projectspage/projectscards/penza-cheese.jpg';
import AltiCardImg from '../../../images/projectspage/projectscards/alti.jpg';
import GjelCardImg from '../../../images/projectspage/projectscards/gjel-cheese.jpg';


const SkladyProjectsCards = () => {
    return(
        <Container fluid>
            <Row>
                <Col>
                    <ProjectCardTemplate 
                        src = {EcomilkCardImg}
                        alt = 'Завод по производству сыров ТМ "Экомилк" Озерецкого молочного комбината'
                        title = 'МК "ОЗЕРЕЦКИЙ"' 
                        subtitle= "Сырное производство"
                        text = 'Завод по производству сыров ТМ "Экомилк" Озерецкого молочного комбината'
                        link = "/realizovannye_proekty/ecomilk-syr"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {MolokoKutuzaCardImg}
                        alt = "Производство и реализация сыров (горгонзола, абонданс, раклет)"
                        title = '"MOLOKO GROUP"' 
                        subtitle= "Кутузовский проспект"
                        text = "Производство и реализация сыров (горгонзола, абонданс, раклет)."
                        link = "/realizovannye_proekty/proectinvest"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {MolokoKalugaCardImg}
                        alt = "Производство сыров Грюйер (головки 720мм по 40кг)"
                        title = '"MOLOKO GROUP"'  
                        subtitle= "Производство сыра г.Калуга"
                        text = "Производство сыров Грюйер (головки 720мм по 40кг)."
                        link = "/realizovannye_proekty/cheese-kaluga"
                    />
                </Col>
                </Row>

                <Row>
                <Col>
                    <ProjectCardTemplate 
                        src = {CheesePleaseCardImg}
                        alt = "Цех по производству сыра «Метромаркет», торговая марка «CHEESE PLEASE!»"
                        title = '"CHEESE PLEASE"' 
                        subtitle= "Производство сыра в г.Пенза"
                        text = "Цех по производству сыра «Метромаркет», торговая марка «CHEESE PLEASE!»"
                        link = "/realizovannye_proekty/penza-cheese"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {AltiCardImg}
                        alt = "Новое производство сыров с белой плесенью."
                        title = '"ALTI"'  
                        subtitle= "Сырный завтод в Москве"
                        text = "Новое производство сыров с белой плесенью."
                        link = "/realizovannye_proekty/milkprom"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {GjelCardImg}
                        alt = "Камера созревания полутвердых и твёрдых сыров для фермы «Гжельское Подворье»."
                        title = '"ГЖЕЛЬСКОЕ ПОДВОРЬЕ"' 
                        subtitle= "Сырное производство г.Гжель"
                        text = "Камера созревания полутвердых и твёрдых сыров для фермы «Гжельское Подворье»."
                        link = "/realizovannye_proekty/gjelskoe-podvorie"
                    />
                </Col>
            </Row>

        </Container>
        
    )
}

export default SkladyProjectsCards