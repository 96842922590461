import React from "react"

const ProjectCardTemplate = ({src, alt, title, subtitle, text, link}) => (
    <>
        <div className="ref-project-card-container">
            <div className="ref-project-card-1">
                <article class="card">
                    <header class="card__thumb">
                        <img src={src} alt={alt}/>
                    </header>
                
          
                    <div className="card__body">
                        <div class="card__title">{title}</div>
                        <div class="card__subtitle">{subtitle}</div>
                        <p>{text}</p>
                    </div>

                    <footer class="card__footer">
                        <span><a href={link}><button class="card__button">О ПРОЕКТЕ</button></a></span>
                    </footer>
                </article>
            </div>
        </div>
    </>
)

export default ProjectCardTemplate;

