import React from "react"
import Seo from "../../components/seo"
import KlimablockHtml from "../../components/EquipmentPages/Klimablock"


const KlimablockPage = () => (

  <>
    <Seo 
      title="Климатическое оборудование для производства сыра"
      description="Климатический блок управления созреванием сыра производства компании Рефинжиниринг. Оборудование для производства сыра собственной разработки."
      keywords="Климатическое оборудование, оборудование для производства сыра, климатический блок, блок управления созреванием сыра, оборудование Рефинжиниринг"
    />
    <KlimablockHtml />
  </>
  
)

export default KlimablockPage