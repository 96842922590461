import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Footer from '../../Modules/FooterPage';
import Lightbox from 'fslightbox-react';
import PageAttHeader from '../../Modules/TextBlocks/PresAttHeader';
import ServiceCard from './servicecard';
import RepairCard from './repaircard';
import FanCard from './fancard';
import KlimablockSlider from "./klimablockslider";
import CheeseCardsThree from "../../Modules/PageCards/cheeseCardThreeRow";


import KlimablockMainImg from "../../../images/equipment/Gjel_2.jpg"
import Stellagka from "../../../images/equipment/stellagy.jpg"
import KlimablockImg  from '../../../images/equipment/klimablock_parts-V2.jpg';
import ChertejImg  from '../../../images/equipment/chertej_klimablock.jpg';
import ServiceMan from '../../../images/equipment/serviceman.jpg';
import UpBlock from '../../../images/equipment/open.jpg';
import FanImg from '../../../images/equipment/fan.jpg';
import SectionsImg from '../../../images/equipment/sections.jpg';
import PsyhometrImg from '../../../images/equipment/klimablock_psyhometr.jpg';
import TouchPanelImg from '../../../images/equipment/touch-panel.jpg';
import GydroImg from '../../../images/equipment/gydro.jpg';
import Gydro2Img from '../../../images/equipment/gydro2.jpg';

function KlimablockParts() {
    const [toggler, setToggler] = useState(false);

    return (
        <>

            <button onClick={() => setToggler(!toggler)}>
                <figure>
                    <img src={KlimablockImg} alt="Устройство климатического блока Рефинжиниринг" style={{width: '100%', cursor: 'zoom-in'}}/>
                </figure>
                <figcaption><em>Нажмите, чтобы увеличить</em></figcaption>
            </button>
        
            <Lightbox
                toggler={ toggler }
                sources={ [
                    <img src={KlimablockImg} alt="Устройство климатического блока Рефинжиниринг" />
                ] }
            />
        </>
    );
}

function ChertejKlimablock() {
    const [toggler, setToggler] = useState(false);

    return (
        <>

            <button onClick={() => setToggler(!toggler)}>
                <figure>
                    <img src={ChertejImg} alt="Устройство климатического блока Рефинжиниринг" style={{width: '100%', cursor: 'zoom-in'}}/>
                </figure>
                <figcaption><em>Нажмите, чтобы увеличить</em></figcaption>
            </button>
        
            <Lightbox
                toggler={ toggler }
                sources={ [
                    <img src={ChertejImg} alt="Чертеж климатического блока Рефинжиниринг" />
                ] }
            />
        </>
    );
}

const fantext = 'с диффузором из нержавеющей стали AISI304, регулируемой скоростью вращения по внешнему сигналу с контроллера. \nВсасывание воздуха снизу и нагнетание в камеру давления текстильных воздуховодов. \nРасход воздуха до 6000 м³/час, напор до 1500Па, энергопотребление до 2,5кВт.'


const KlimablockHtml = () => {

    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>

            <div className="refeng-page-container">
                <div className="postcontent">
                    <h1>Климатическое оборудование для камер созревания сыров</h1>
                    <h2>Климатический блок производства компании "Рефинжиниринг"</h2>
                    {/* <p>Узнать подробнее о работе климатичского блока производства ООО "Рефинжиниринг" можно на странице выполненного проекта 
                        <a href='/realizovannye_proekty/gjelskoe-podvorie'> сырной камеры фермы "Гжельское Подворье"</a></p> */}
<br/>
                   
                <Container fluid>
                    
                    <Row>
                        <Col xl={7}>
                                <br/>
                            <PageAttHeader 
                                text= "Единый климатический блок для точного поддержания температурно-влажностного режима (микроклимата):" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '0'}}>Охлаждение;</li>
                                <li className='post-li-checked' style={{lineHeight: '0'}}>Нагрев;</li>
                                <li className='post-li-checked' style={{lineHeight: '0'}}>Осушка;</li>
                                <li className='post-li-checked' style={{lineHeight: '0'}}>Увлажнение;</li>
                                <li className='post-li-checked' style={{lineHeight: '0'}}>Интенсивность обдува продукта.</li>
                            </ul>
                            <br/>
                            <PageAttHeader 
                                text= "Напольное исполнение блока для максимально эффективной циркуляции воздуха внутри камеры:" 
                            />
                            <p>Всасывание воздуха осуществляется с уровня пола.
                                Подготовленный в климатическом блоке воздух подается
                                в текстильные воздуховоды. Точный подбор перфорации
                                и сопел в воздуховодах обеспечивает равномерное
                                распределение воздуха по всему объему камеры и
                                продукту вне зависимости от конфигурации и расстановки
                                стеллажного оборудования.
                            </p>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={2}><img src={KlimablockMainImg} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" /></Col>
                        <Col xl={2}></Col>
                    </Row>

                    <Row>
                        <Col>
                            <figure>
                                <img src={Stellagka} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" />
                                <figcaption><em>Очищенный воздух равномерно распределяется в камере созревания между стеллажами, предотвращая чрезмерную осушку продукта.</em></figcaption>
                            </figure>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col  xl={8}>
                            <PageAttHeader 
                                text= 'Климатический блок разработан компанией "Рефинжиниринг", с учетом накопленных компетенций в сфере климатических камер созревания различных продуктов. Устройство нашего климаблока:'
                            />
                             <ul>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Кольцевой выступ для фиксации текстильного воздуховода;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Поворотный шарнир для смещения вентиляторного блока и обслуживания внутренней части;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Струбцина для фиксации вентиляторного блока;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Верхний датчик температуры;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Секция нагрева;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Секция охлаждения и конденсации влаги;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Отделитель жидкого хладагента;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Нижний датчик температуры;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Регулируемые опоры с отверстиями для фиксации анкерами к полу;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Центробежный ЕС-вентилятор;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Сдвоенная маслоподъёмная петля для варианта исполнения с хладагентом;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Паук (распределитель хладагента;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Верхняя двухфазная форсунка;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Ротаметры для настройки расхода сжатого воздуха;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Распределитель сжатого воздуха и воды в корпусе IP65 с прозрачной крышкой;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Смотровое стекло для наблюдения конденсации и отсутствия обмерзания;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Нижняя двухфазная форсунка;</li>
                                <li className='post-li-checked' style={{lineHeight: '1'}}>Поддон для сбора конденсата (съёмная поворотная конструкция);</li>
                            </ul>
                        </Col>
                        <Col></Col>
                        <Col  xl={3}>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <KlimablockParts />
                            <br/>
                            <br/>
                            <ChertejKlimablock />
                        </Col>
                    </Row>
                    <br/>
                    
                    <Row>
                        <Col>
                            <h3>Основные преимущества климатического блока "Рефинжиниринг":</h3>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <ServiceCard 
                                src= {ServiceMan}
                                alt="Фармацевтические склады для климатических условий хранения медикаментов"
                                title="Удобство в обслуживании"
                                text="Поворотный шарнир позволяет обслуживающему и эксплуатирующему персоналу получить простой доступ для мойки или ремонта внутренней части климатического блока."
                                title2="Отсутствуют закрытые профили или участки недоступные для мойки"
                                title3="Смотровые стёкла для наблюдения за конденсацией влаги на секции охлаждения и отсутствия обмерзания"
                                title4="Теплообменники нагрева и охлаждения, корпус и поддон полностью из нержавеющей стали AISI304"
                                title5="Свободное стекание воды и моющих растворов без скопления внутри."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <RepairCard 
                                src= {UpBlock}
                                alt="Фармацевтические склады для климатических условий хранения медикаментов"
                                title="100% ремонтопригодность"
                                text="Теплообменный блок не имеет блокирующих креплений и легко извлекается с помощью тали."
                                title2="Трубопроводы секции охлаждения и нагрева имеют подключение в зоне обслуживания под смещаемой крышкой вентиляторного блока."
                                title3="Трубопроводы теплообменников не имеют коллекторов и соединений внутри блока, они ремонтопригодны (сварка в защитной среде аргона)."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FanCard 
                                src= {FanImg}
                                alt="Фармацевтические склады для климатических условий хранения медикаментов"
                                title="Центробежный высоконапорный энергоэффективный ЕС-вентилятор"
                                text={fantext}
                            />
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col>
                            <h3>Варианты исполнения и опциональное оснащение:</h3>
                        </Col>
                    </Row>
                    <Row style={{}}>
                        <Col xl={7} style={{alignSelf: 'center'}}>
                            <PageAttHeader 
                                text= "Секция нагрева может быть выполнена:" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к существующей системе горячей воды Заказчика (оборотная техническая вода с температурой более +25°С или система ГВС, 
                                рекомендуется при варианте подключения секции охлаждения к ледяной воде);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к контуру гликоля с температурой более +25°С (включено в предложение 15кВт);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к контуру конденсатора холодильного агрегата (фреон R-410A, 507A, 134A или любые другие марки фреона на выбор Заказчика);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Из кольцевого ТЭНа выполненного из нержавеющей стали AISI304.</li>
                            </ul>
                            <br/>
                            <PageAttHeader 
                                text= "Секция охлаждения может быть выполнена:" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к существующей системе ледяной воды Заказчика (оборотная техническая вода с температурой +1..+2°С);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к контуру пропиленгликоля с температурным режимом -1°С;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Для подключения к отдельному агрегату или общей системе холодоснабжения с прямым кипением хладагента (фреон R-410A, 507A, 134A или любые другие марки фреона на выбор Заказчика).</li>
                            </ul>
                            <br/>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={4}><img src={SectionsImg} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" /></Col>
                    </Row>
<hr></hr>
                    <Row>
                        <Col xl={6}><img src={PsyhometrImg} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" /></Col>
                        <Col xl={1}></Col>
                        <Col xl={5} style={{alignSelf: 'center'}}>
                            <PageAttHeader 
                                text= "Надёжный психрометр" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Выполнен полностью из нержавеющей стали AISI304;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Всего два высокоточных датчика подключены к главному шкафу управления (мокрый и сухой термометр);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Третий резервный датчик (сухой термометр);</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Отсутствие инерционности;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Точность измерения обеспечивается постоянным протоком воздуха через датчики;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Простая конструкция и обслуживание.</li>
                            </ul>
                            <br/>
                            <PageAttHeader 
                                text= "Система CIP мойки" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>С индивидуальным генератором пены и ёмкостями с моющими и ополаскивающими растворами.</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>С подключением к существующей системе CIP мойки предприятия.</li>
                            </ul>
                            <br/>
                        </Col>
                    </Row>
                    <hr></hr>
                    <br/>
                    <Row>
                        <Col xl={7} style={{alignSelf: 'center'}}>
                        <PageAttHeader 
                                text= "Шкаф управления климатической установкой:" 
                            />
                            <ul>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>На базе свободнопрограммируемых контроллеров <strong>Carel (Италия)</strong>;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Степень защиты корпуса не менее IP54;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Предусмотрена настройка камеры созревания на различные режимы работы;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Корректировка программы под требования Заказчика; </li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Предусмотрена возможность подключения системы мониторинга и диспетчеризации, возможность подключения контроллера к сети ethernet завода;</li>
                                <li className='post-li-checked' style={{lineHeight: '2'}}>Тач-панель с красивой визуализацией и удобной настройкой параметров;</li>
                            </ul>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={4}><img src={TouchPanelImg} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" /></Col>
                    </Row>
                    <hr></hr>
                    <br/>
                   
                    <Row>
                        <Col xl={5} style={{alignSelf: 'center'}}>
                            <PageAttHeader 
                                text= "Обвязка по хладагенту, обвязка по теплоносителю, система двухфазных форсунок с блоком подготовки сжатого воздуха с безмасляным компрессором." 
                            />
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={3}>
                            <img src={GydroImg} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" />
                        </Col>
                        <Col xl={3}>
                            <img src={Gydro2Img} style={{width:"100%"}} alt="Климатическое оборудование производства компании 'Рефинжиниринг'" />
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col>
                            <p className='post-header'>Климатические блоки, разработанные компанией "Рефинжиниринг" это точное управление температурой, влажностью и воздушными потоками для производства продуктов наивысшего качества, при 
                            весьма внушительной экономии для Производителя. Для более подробной информации о работе климатического блока и нашем опыте в устройстве камер созревания вы можете узнать, связавшись с менеджером по обратной связи или 
                            по телефону: <strong>+7(495)72-101-11</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <h4>Галерея климатического блока производства "Рефинжиниринг":</h4>
                            <KlimablockSlider />
                        </Col>
                    </Row>
                    <hr></hr>
                    <br/>
                    <Row>
                        <Col>
                        <h4>Завершенные проекты сырного производства компанией "Рефинжиниринг":</h4>
                        <br/>
                            <CheeseCardsThree />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{display: 'grid', justifyContent: 'center'}}>
                             <a href="/projects"><button className="ref-button-stoped">ВСЕ ПРОЕКТЫ</button></a>
                            </div>
                        </Col>
                    </Row>

                    
                </Container>
                <br/>
                </div>
            </div>

            <Footer />
        </>
            )
    }

    export default KlimablockHtml;