import React from 'react';
import PageAttIcon from '../../Elements/Icons/Pageicons/pageAttIcon';

const PageAttHeader = ({text}) => {
    return (
        <div style={{ display: 'flex' }}>
            <div><PageAttIcon /></div>
            <span className='pageattheader'>{ text }</span>
        </div>
    )
}

export default PageAttHeader