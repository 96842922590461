import React from 'react';
import KlimablockHtml from './klimablockhtml'


const Klimablock = () => {
    return(
        <KlimablockHtml />
    )
}

export default Klimablock